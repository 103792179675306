export const environment = {
  production: false,
  baseUrl: 'https://geoappbacksvc-development.azurewebsites.net/api/',
  logoutUrl: '',
  labTechGroupId: 'e4097555-0942-4fa9-8b7d-d5ab8481f59a',
  labManagerGroupId: 'be33ffe4-28c8-48bf-a3bd-a03386eb99dd',
  reportWriterGroupId: '7572a879-972f-49fe-ba8c-4ab24e896153',
  adminGroupId: '49ff3c55-6a6a-4da4-8400-be42fefafd6a',
  appInsights: {
    instrumentationKey: 'd184fc63-7756-4f2b-88d9-11b2cc933f32',
  },
  contactEmail: 'support@geothermusa.com',
};

export const AzureAdB2C = {
  clientId: 'b1d9c777-0b1c-45e3-9b82-db5de2bb2a58',
  redirectUri: 'https://geoapp-dev.geothermusa.com/',
  authorty: 'https://login.microsoftonline.com/7618b1f7-7cda-4be7-9f85-f7c80a1e0c8b',
  scope: ['user.read'],
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/', ['user.read']],
    [`${environment.baseUrl}lookup/getprojectstatuses`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}lookup/getsignaturetypes`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}lookup/getsamplestatuses`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}dashboard`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}reports`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}precastws`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}nativesoilws`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}company`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}project`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}note`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}notification`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}map`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}storage`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}sample`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}admin`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}user`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}ml`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}auditlog`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}auditlog/getbloburl`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}lookup/getalluseremails`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}lookup/getauditresourcetypes`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
    [`${environment.baseUrl}lookup/getauditactiontypes`, ['api://c6717609-076f-4694-8137-229e5fa86121/GeoAPP.read']],
  ]),
  cacheLocation: 'localStorage',
};

export const googleCaptcha = {
  siteKey: '6LeUjFcoAAAAAC4zKcmbLXAg3eXAOw4VYSorcgsW',
};

export const geoXplore = {
  accessToken: 'pk.eyJ1IjoidXBlbm5kZXJyYW5lcnUiLCJhIjoiY2xsYjJjenpoMDJ2NzNmcXQxazdvZnFjNiJ9.uK17wUrxnk0SVmSKR3os-Q',
};
